import React from 'react';
import './section.css';
import UserComments from '../usercomments/UserComments'; // Перевірте правильність шляху

const Section7 = () => (
  <>
 
  <section id="section7">
    <h1 id="vidguk_page_7_h1">Відгуки</h1>
    <div className="block_page_7_main">
      <div className="block_vidhuku">
        <div className="spanKartochka_1">
          <img src="/img/comentUser.jpg" alt="MilArt" className="vidguk-image" />
          <h3>Козак Aнатолій</h3>
          <p>"Взяв ваш спортивний костюм — тепер ніби створений для руху. Ідеально підходить і для залу, і для прогулянок, ще й виглядає настільки стильно, що друзі вже питали, де я його знайшов. Дякую за крутий одяг без зайвих слів!"</p>
        </div>
        <div className="spanKartochka_2">
          <img src="/img/UserComent.webp" alt="MilArt" className="vidguk-image" />
          <h3>Артур Кліфко</h3>
          <p>
            "Купив ваше худі і вже встиг провести ефір на своєму ютуб-каналі. Результат 
просто вражаючий: лайків стало втричі більше, а в коментах — захоплення! Виглядає круто, 
відчувається комфортно, і навіть враження в мережі не змусили себе чекати. Дякую за стильний та якісний одяг!"</p>
        </div>
        <div className="spanKartochka_3">
          <img src="/img/card_shirt2.png" alt="MilArt " className="vidguk-image" />
          <h3>John woody</h3>
          <p>"Придбав ваш спортивний костюм і одразу відчув різницю. Качество на висоті, сидить як влитий, і настільки зручний, що навіть не хочеться знімати. Чудово підходить як для тренувань, так і для звичайних прогулянок. Виглядає мінімалістично, 
            але при цьому дуже стильно. Справжній must-have для кожного, хто цінує комфорт і стиль. Респект вам за таку продукцію!"</p>
        </div>
      </div>
    </div>
    <UserComments /> {/* Додаємо компонент UserComments знизу */}
  </section>
  </>
);

export default Section7;
