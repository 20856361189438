import React, { useState } from "react";
import "./section.css";

const SectionForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    product: "",
    size: "",
    address: "",
    shipping: "",
    Branch: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "radio" ? (checked ? value : "") : value,
    }));
  };

  const sendToTelegram = async (message) => {
   
    const token = process.env.REACT_APP_TELEGRAM_BOT_TOKEN; // Для локальної розробки
    const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID; // Для локальної розробки
    try {
      const response = await fetch(
        `https://api.telegram.org/bot${token}/sendMessage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error sending message: ${response.statusText}`);
      }

      console.log("Message sent successfully");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("There was an error sending the message. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.phone ||
      !formData.product ||
      !formData.size ||
      !formData.address ||
      !formData.shipping ||
      !formData.Branch
    ) {
      alert("Будь ласка, заповніть всі поля");
      return;
    }

    const message = `Замовлення:
      Ім'я та Прізвище: ${formData.name}
      Номер телефону: ${formData.phone}
      Товар: ${formData.product}
      Розмір: ${formData.size}
      Адреса доставки: ${formData.address}
      Спосіб доставки: ${formData.shipping}
      Відділеня нової пошти: ${formData.Branch}
    `;

    await sendToTelegram(message);

    setFormData({
      name: "",
      phone: "",
      product: "",
      size: "",
      address: "",
      shipping: "",
      Branch: "",
    });

    alert("Замовлення успішно відправлено!");
  };

  return (
    <>
    
    <section id="SectionForm">
      <div id="deskotop_page_9">
        <form onSubmit={handleSubmit}>
          <h1 id="zamovutu-h1">Замовити</h1>
          <div className="block_page_9_zamovlenja">
            <label className='labelForm'  htmlFor="namefirstandlast"> Ім'я та Прізвище *</label>
            <input
              id="namefirstandlast"
              name="name"
              type="text"
              placeholder="Ім'я та Прізвище *"
              value={formData.name}
              onChange={handleChange}
            />        
            <label className='labelForm'  htmlFor="numberZamovlenja"> Номер телефону *</label>
            <input
              id="numberZamovlenja"
              name="phone"
              type="text"
              placeholder="Телефон * +380"
              value={formData.phone}
              onChange={handleChange}
            />
         
            <div className="select-container">
              <label className='labelForm'  htmlFor="product-select">Товар:</label>
              <select
                id="product-select"
                name="product"
                value={formData.product}
                onChange={handleChange}
              >
                <option value="Товар для відправлення"> Оберіть товар</option>
                <option value="Костюм + барсетка">Костюм + барсетка</option>
                <option value="Курточка">Курточка</option>
                <option value="Штани">Штани</option>
              </select>
            </div>
        
            <div className="select-container">
              <label className='labelForm' htmlFor="size-select">Розмір:</label>
              <select
                id="size-select"
                name="size"
                value={formData.size}
                onChange={handleChange}
              >
                <option value="">Оберіть розмір</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="M">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
                <option value="XXXL">XXXL</option>
              </select>
            </div>
      
            <label className='labelForm'  htmlFor="address"> Місто *</label>
            <input
              className="adresDostavku"
              id="address"
              name="address"
              type="text"
              placeholder="Адреса доставки *"
              value={formData.address}
              onChange={handleChange}
            />
               <label className='labelForm'  htmlFor="Branch"> Відділення *</label>
            <input
              className="adresDostavku"
              id="Branch"
              name="Branch"
              type="text"
              placeholder="номер відділеня пошти *"
              value={formData.Branch }
              onChange={handleChange}
            />
            <div>
              <label className="labelDostavkaBlock">
                <input
                  className="radioButton"
                  type="radio"
                  name="shipping"
                  value="Нова Пошта"
                  checked={formData.shipping === "Нова Пошта"}
                  onChange={handleChange}
                  required
                />
                <span className="textDostavkaSposib">
                 Доставка: Нова Пошта<br/>
                  Оплата: післяплата
                </span>
              </label>
            </div>
      
            <button className="pidtverdutu" type="submit" id="pidtverdutu">
            Замовити
            </button>
          </div>
        </form>
      </div>
    </section>
    </>
  );
};

export default SectionForm;
