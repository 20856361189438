import React from "react";
import "./section.css";

const Section4 = () => (
  <>
     
  <section id="section4" className="section4">
    <div className="block_page_4" id="block_page_4">
      <div className="block_page_h2">
        <h2>Не пропустіть свій шанс отримати подарунок!</h2>
      </div>
      <div className="block_page_Img_4">
        <img src="/img/block_page_4_img.png" alt="подарунок, спортивний костюм, барсетка" />
      </div>
      <div className="block_page_4_text">
        <p>
          Замовляйте спортивний костюм прямо зараз і скористайтеся нашою
          вигідною пропозицією.
        </p>
        <br />
        <p>
          Тільки зараз, купуючи цей костюм, ви отримаєте стильну барсетку <br />
          <b>абсолютно безкоштовно!</b>
        </p>
      </div>
      <a href="#section5" className="button_page_4">
        <p id="button_page_4_text">хочу подарунок</p>
      </a>
    </div>
  </section>
  </>
);

export default Section4;
