import React, { useState } from 'react';
import './section.css';

const Section5 = () => {
  const [modalImages, setModalImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [zoomImage, setZoomImage] = useState(null); // Для зображення, яке збільшується

  const openGallery = (images) => {
    setModalImages(images);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalImages([]);
    setZoomImage(null); // Скидаємо збільшене зображення
  };

  const handleImageClick = (image) => {
    setZoomImage(image); // Встановлюємо зображення для збільшення
  };

  const closeZoom = () => {
    setZoomImage(null); // Закриваємо збільшене зображення
  };

  const productGalleries = {
    product1: [
      '/img_galeri/foto_galeri1.svg',
      '/img_galeri/foto_galeri2.svg',
      '/img_galeri/foto_galeri3.svg',
      '/img_galeri/foto_galeri4.svg',
      '/img_galeri/foto_galeri5.svg',
    ],
    product2: [
      '/img_galeri/foto_galeri6.svg',
      '/img_galeri/foto_galeri7.svg',
      '/img_galeri/foto_galeri8.svg',
      '/img_galeri/foto_galeri1.svg',
      '/img_galeri/foto_galeri2.svg',
    ],
    product3: [
      '/img_galeri/foto_galeri3.svg',
      '/img_galeri/foto_galeri4.svg',
      '/img_galeri/foto_galeri5.svg',
      '/img_galeri/foto_galeri6.svg',
      '/img_galeri/foto_galeri7.svg',
    ],
  };

  return (
    <>
      <section id="section5" className="section5">
        <div className="product-container">
          <div className="product-card" onClick={() => openGallery(productGalleries.product1)}>
            <img src="/img/card_shirt.png" alt="Товар 1" className="product-image" />
            <h3>Костюм + барсетка</h3>
            <p>Ціна: 1999 грн</p>
            <a href="#SectionForm"><button className="order-button">Замовити</button></a>
          </div>
          <div className="product-card" onClick={() => openGallery(productGalleries.product2)}>
            <img src="/img/card_shirt2.png" alt="Товар 2" className="product-image" />
            <h3>Курточка</h3>
            <p>Ціна: 1299 грн</p>
            <a href="#SectionForm"><button className="order-button">Замовити</button></a>
          </div>
          <div className="product-card" onClick={() => openGallery(productGalleries.product3)}>
            <img src="/img/card_shirt3.png" alt="Товар 3" className="product-image" />
            <h3>Штани</h3>
            <p>Ціна: 999 грн</p>
            <a href="#SectionForm"><button className="order-button">Замовити</button></a>
          </div>
        </div>

        {/* Модальне вікно з галереєю */}
        {isOpen && (
          <div className="modal" onClick={closeModal}>
            <span className="close">&times;</span>
            <div className="modal-content">
              {modalImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Зображення ${index + 1}`}
                  className="modal-image"
                  onClick={(e) => {
                    e.stopPropagation(); // Запобігає закриттю модального вікна
                    handleImageClick(img);
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {/* Модальне вікно для збільшення зображення */}
        {zoomImage && (
          <div className="zoom-modal" onClick={closeZoom}>
            <img src={zoomImage} alt="Збільшене зображення" className="zoomed-image" />
          </div>
        )}
      </section>
    </>
  );
};

export default Section5;
