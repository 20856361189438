import React from 'react';
import './section.css';
import { Helmet } from 'react-helmet';


const Section1 = () => (
  <>
  <Helmet>
    <title>Milart Store - Осінні пропозиції</title>
    <meta name="keywords" content="чоловічий спортивний одяг, спортивні костюми для чоловіків, чоловічі тренувальні футболки, одяг для фітнесу чоловічий, чоловічі спортивні штани, чоловічі спортивні шорти, одяг для бігу чоловікам, чоловічий одяг для тренувань, чоловічі спортивні аксесуари, функціональний спортивний одяг для чоловіків, чоловічі спортивні толстовки, мода для спорту чоловікам, чоловічі футболки для фітнесу, товари для спорту чоловічі, чоловічі спортивні бренди одягу, якість, швидкість виготовлення, етичні умови праці, подарунок, спортивний костюм, барсетка, вигідна пропозиція, акція на одяг, спортивні костюми, курточки, штани, чоловічий одяг, замовлення одягу, ціна на одяг, розмірна сітка, як вибрати розмір, спортивний одяг, розміри для чоловіків, розмірний гід, таблиця розмірів, спортивні костюми, замовлення, оформлення замовлення, форма замовлення, доставка, Telegram"/>
  <meta name="description" content="Milart Store - чоловічий спортивний одяг високої якості. Спортивні костюми, футболки, шорти, штани, аксесуари для фітнесу та бігу. Великий вибір одягу для тренувань та активного відпочинку. Не пропустіть свій шанс отримати стильну барсетку безкоштовно при покупці спортивного костюма. Оформіть замовлення на чоловічий спортивний одяг через форму на нашому сайті."/>

    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </Helmet>
  <section id="section1">
      <h1 className="page1_h1">А ти вже підготувався до осені?</h1>
      <h2 className="page1_h2"> 
        <b>Купуйте костюм зараз</b> 
        <span id="text_h2_page1"> і отримайте стильну </span> 
        <b>барсетку в подарунок!</b>
      </h2>
      <a href="#section5" className="button_2_page_1" aria-label="Отримати подарунок">
        хочу подарунок
      </a>
      <a href="#section4" className="button_page1" aria-label="Дізнатися більше про пропозицію">
        дізнатися пропозицію
      </a>   
     
      <img src="/img/img_page1.png" alt="Костюм для осені з подарунковою барсеткою" className="small-image" />
  </section>
  </>
);

export default Section1;
